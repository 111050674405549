.header-content{
    min-height: 60vh;
    background: linear-gradient(#107a40b7,  #f8f9fa), url("../../images/library-img.jpg") no-repeat;
    background-size: cover;
    background-position: 40% 0%;
    flex-direction: column;
}
.header-title{
    font-size: 4.2rem;
    font-family: 'hawi_t_Diwany', sans-serif;

}
.header-text{
    margin-top: -0.8rem;
    margin-bottom: 2.8rem;
    opacity: 0.8;
    max-width: 770px;
}