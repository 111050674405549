.search-form{
    width: 100%;
    max-width: 680px;
}
.search-form-elem{
    padding: 1.1rem 2.8rem;
    border-radius: 3.8rem;
}
.search-form-elem .form-control{
    font-size: 2rem;
    padding: 0.6rem;
}
.search-form-elem .form-control::placeholder{
    opacity: 0.9;
}

.anim {
    
    animation: spin 1s linear infinite;
    display: inline-block;
    font-size: 2rem;
    margin-left: 1rem;
    opacity: 0.8;
    transform-origin: center;
    width: 1.5rem;
    height: 1.5rem;
    color: var(--purple-color);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}