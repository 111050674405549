.navbar{
    padding: 1rem 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    background: #fcfcfc63;
    position: absolute;
    width: 100%;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    
}





.navbar-brand{
    width: 100%;
}

.navbar-brand img{
    width: 4rem;
    height: 8rem;
    object-fit: contain;
    margin-left: 2rem;
}
.navbar-brand span{
    transform: translateY(0.5rem);
}
.brand-and-toggler{
    width: 100%;
}
.navbar-content{
    display: flex;
    width: 100%;
}
.navbar-toggler-btn{
    transition: var(--transition);
    z-index: 11;
}
.navbar-collapse{
    position: fixed;
    right: 0;
    top: 0;
    z-index: 10;
    background-color: var(--black-color);
    height: 100%;
    width: 280px;
    display: flex;
    padding: 10rem 3rem 0 3rem;
    transform: translatex(100%);
    transition: var(--transition);
}
.show-navbar-collapse{
    transform: translateX(0);
}
.nav-item{
    margin-bottom: 1.4rem;
}
.nav-link{
    transition: var(--transition);
}
.nav-link:hover{
    color: var(--purple-color);
}

@media screen and (max-width: 768px){
    .navbar-nav {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
    }

    .nav-item {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0 !important;
    }
    .navbar-collapse {
        padding: 0 !important;
        background-color: transparent;

    }


    
    
}


@media screen and (min-width: 992px){
    .navbar-collapse{
        position: relative;
        height: auto;
        padding: 0;
        width: 100%;
        background-color: transparent;
        transform: translateX(0);
        justify-content: flex-end;
    }
    .navbar-toggler-btn{
        display: none;
    }
    .nav-item{
        margin-bottom: 0;
        margin-left: 2.8rem;
    }
    .nav-link{
        color: var(--black-color);
        font-size: 1.7rem;
        font-weight: 500!important;
        opacity: 0.9;
    }
    .navbar-nav{
        display: flex;
    }


}

.mosque {
    font-family: 'hawi_t_Diwany', sans-serif !important;
    font-size: 2.5rem;
    font-weight: 700;
  }

  /* Navbar.css */
.navbar-collapse {
    display: flex;
    align-items: center;
    justify-content: center;
    /* Ajoutez d'autres styles selon vos besoins */
  }
  
  .dropdown {
    position: relative;

  }
  
  .dropdown-btn {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .dropdown-arrow {
    margin-left: 0.5rem;
  }
  
  .dropdown-menu {
    opacity: 0;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #e4e4e4; /* Modifiez selon vos préférences */
    list-style: none;
    padding: 20px 10px;
    margin: 0;
    min-width: 160px;
    transition: opacity 0.3s;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;
  }
  
  .dropdown-menu .dropdown-item {
    padding: 0.5rem 1rem;
  }
  
  .dropdown-menu .dropdown-item a {
    text-decoration: none;
    color: #302d2d;
    display: block;
    font-size: 1.6rem;
  }
  
  .dropdown:hover .dropdown-menu {
    display: block;
    opacity: 1;
  }
  