@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
:root{
  --purple-color: #107a40;
  --pink-color: #d9176d;
  --grey-color: #f3f3f3;
  --white-color: #fff;
  --black-color: #010101;;
  --light-black-color: #717171;
  --transition: all 300ms ease-in-out;
}
@font-face {
  font-family: 'hawi_t_Diwany';
  src: url('./fonts/hawi_t_Diwany.woff2') format('woff2'),
      url('./fonts/hawi_t_Diwany.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@import url('https://fonts.googleapis.com/css2?family=Zain:wght@200;300;400;700;800;900&display=swap');

@font-face {
  font-family: 'Zain';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./fonts/Zain.woff2') format('woff2');
  unicode-range: U+0600-06F;
}
@font-face {
  font-family: 'El Messiri';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./fonts/ElMessiri.woff2') format('woff2');
  unicode-range: U+0600-06F;
}


*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'El Messiri', 'Poppins', sans-serif !important;

}
html{
  font-size: 10px;
}
body{
  font-size: 1.6rem;
  line-height: 1.7;
  font-family: 'El Messiri', 'Poppins', sans-serif !important;
  color: var(--black-color);
  direction: rtl;
  background-color: #f8f9fa
}

/* resets */
ul{
  list-style-type: none;
}
a{
  text-decoration: none;
  color: var(--black-color);
}
button{
  outline: 0;
  border: none;
  font-family: inherit;
  background-color: transparent;
  cursor: pointer;
}
img{
  width: 100%;
  display: block;
}
input{
  border: none;
  outline: 0;
  font-family: inherit;
  font-size: 1.6rem;
}
.resize-animation-stopper *{
  animation: none!important;
  transition: none!important;
}

/* backgrounds */
.bg-pink{background-color: var(--pink-color);}
.bg-purple{background-color: var(--purple-color);}
.bg-white{background-color: var(--white-color);}

/* text */
.text-black{color: var(--black-color);}
.text-white{color: var(--white-color);}
.text-purple{color: var(--purple-color);}
.text-light-black{color: var(--light-black-color);}
.text-uppercase{text-transform: uppercase;}
.text-center{text-align: center;}
.text-capitalize{text-transform: capitalize;}
.text-italic{font-style: italic;}

/* font weights */
.fw-3{font-weight: 300;}
.fw-4{font-weight: 400;}
.fw-5{font-weight: 500;}
.fw-6{font-weight: 600;}
.fw-7{font-weight: 700;}
.fw-8{font-weight: 800;}
.fw-9{font-weight: 900;}

/* font sizes */
.fs-15{font-size: 1.5rem;}
.fs-16{font-size: 1.6rem;}
.fs-17{font-size: 1.7rem;}
.fs-18{font-size: 1.8rem;}
.fs-20{font-size: 2rem;}
.fs-22{font-size: 2.2rem;}
.fs-24{font-size: 2.4rem;}
.fs-26{font-size: 2.6rem;}

/* letter spacing */
.ls-1{letter-spacing: 1px;}
.ls-2{letter-spacing: 2px;}

.flex{
  display: flex;
  align-items: center;
}
.flex-column{
  flex-direction: column;
}
.flex-sb{
  justify-content: space-between;
}
.flex-c{
  justify-content: center;
}
.grid{
  display: grid;
}
.container{
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

/* section title */
.section-title h2{
  font-weight: 600;
  text-transform: uppercase;
  padding: 18px 0 24px 0;
}