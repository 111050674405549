/* src/components/Layout/Layout.css */
.layout {
  display: flex;
  min-height: 100vh;
}

.sidebar {
  width: 250px;
  background-color: rgb(249, 249, 249);
  padding: 20px;
}

.sidebar * {
  font-size: 1.6rem !important;
}
.content {
  flex: 1;
  padding: 20px;
  background-color: #fff;
}
.content * {
  font-size: 1.6rem !important;
}
.sidebar-toggle{
  display: none;
  position: absolute;
  height: 30px;
  z-index: 10000;
  left: -20px;
  border-radius: 50%;
  width: 30px;
  transform: translate(0, -50%);
  top: 50%;
  right: unset;
  background: #111;
  color: #fff;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  text-align: center;
  cursor: pointer;
}
@media screen and (max-width: 768px) {

  .sidebar {
    width: 80% !important;
    position: fixed !important;
    z-index: 1000;
    height: 100%;
    transition: 0.5s;
  }

  .ps-menu-label button {
    display: none !important;
  }

  .sidebar-toggle {
    display: flex;
  }

  .content {
    overflow: auto;
  }
}