.booklist, .book-details{
    padding: 3rem 0;
    background-color: #f8f9fa00;
}
.booklist-content{
    gap: 3rem;
}
.book-item{
    padding: 3rem 2rem;
    border-radius: 4px;
    background-color: var(--white-color);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 15px 0px;
    transition: var(--transition);
}
.book-item:hover{
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.book-item-img {
    position: relative;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.book-item-img img{
    object-fit: contain;
    max-height: 180px;
    margin-right: auto;
    margin-left: auto;
}
.book-item-info{
    margin-top: 2rem;
}
.book-item-info-item.title{
    line-height: 1.4;
    margin-bottom: 0.8rem;
}
.book-item-info-item.author{
    line-height: 1.4;
    margin-bottom: 4px;
}
.book-item-info-item:not(.book-item-info-item.title) span:last-child{
    opacity: 0.8;
}
.book-item-info-item.publish-year{
    opacity: 0.6;
    font-style: italic;
    font-size: 14px;
}

@media screen and (min-width: 600px){
    .booklist-content{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (min-width: 992px){
    .booklist-content{
        grid-template-columns: repeat(3, 1fr);
    }
    .book-item-img img{
        max-width: 150px;
    }
}

@media screen and (min-width: 992px){
    .booklist-content{
        grid-template-columns: repeat(4, 1fr);
    }
    .book-item-img img{
        max-width: 120px;
    }
}


.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination-btn {
    background-color: #107a40;
    border: none;
    color: white;
    padding: 10px 15px;
    margin: 0 10px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 5px;
  }
  
  .pagination-btn:disabled {
    background-color: #d3d3d3;
    cursor: not-allowed;
  }
  
  .pagination-btn:hover:not(:disabled) {
    background-color: #107a4077;
  }
  
  .pagination-info {
    font-size: 16px;
    color: #333;
  }
  